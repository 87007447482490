'use client'

import React from 'react'
import { useTranslations } from 'next-intl'
import { Tab, TabContent, TabList, TabTrigger } from 'component/ui/tabs'
import { parseAsStringEnum, useQueryState } from 'nuqs'
import { TabKey } from 'type/profile'
import { flushSync } from 'react-dom'

interface IProfileTabProps {
  media: React.ReactNode
  activities: React.ReactNode
}

const ProfileTab = ({ media, activities }: IProfileTabProps) => {
  const t = useTranslations()

  const [tab, setTab] = useQueryState(
    'tab',
    parseAsStringEnum<TabKey>(Object.values(TabKey)).withDefault(
      TabKey.Activities
    )
  )

  const scrollTop = React.useRef({
    [TabKey.Activities]: 0,
    [TabKey.Media]: 0
  })

  const handleChange = (value: string) => {
    // record current scroll position
    scrollTop.current[tab] = window.scrollY

    flushSync(() => {
      setTab(value as TabKey)
    })

    // scroll to previous position
    window.scrollTo({
      top: scrollTop.current[value as TabKey]
    })
  }

  return (
    <Tab orientation='horizontal' value={tab} onValueChange={handleChange}>
      <TabList>
        <TabTrigger value={TabKey.Activities} className='w-1/2'>
          {t('tab_activities')}
        </TabTrigger>
        <TabTrigger value={TabKey.Media} className='w-1/2'>
          {t('common.media')}
        </TabTrigger>
      </TabList>
      <TabContent value={TabKey.Activities}>{activities}</TabContent>
      <TabContent value={TabKey.Media}>{media}</TabContent>
    </Tab>
  )
}

export default ProfileTab
